<template>
  <div class="summary-product summary-membership">
    <div class="summary-product__top">
      <div class="summary-product__top--left flex flex-direction--column">
        <h5 class="text-m text-md-m text-bold" :class="[{ 'text-green': isGreen }]">
          {{ getPartnerDiscountText() }}
        </h5>
        <p v-if="description" class="text-sm-s text-md-s m-t-xxs" :class="[{ 'text-green': isGreen }]">
          {{ description }}
        </p>
      </div>
      <div class="summary-product__top--right">
        <span v-if="isPercentageDiscount" class="text-m text-md-m text-bold" :class="[{ 'text-green': isGreen }]"
          >{{ getPartnerDiscountPrice() }}
        </span>
        <div v-else class="flex gap-x-xs text-sm-m align-items--flex-end">
          <i aria-hidden="true" class="icon icon-discount"></i><span>{{ $t('bonus') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SummaryMembership',
  data() {
    return {
      discountPercentage: this.$store.state.config?.discountType?.discountPercentage,
      discountWithoutPorting: this.$store.state.config?.discountType?.discountWithoutPorting == 1 ? true : false,
      discountSummaryDescription: this.$store.state.config?.discount?.discountSummaryDescription,
      discountSummaryDescriptionMulti: this.$store.state.config?.discount?.discountSummaryDescriptionMulti,
      discountBasketDescription: this.$store.state.config?.discount?.discountBasketDescription,
      denotePostfix: this.$t('price-denote') + this.$t('price-postfix'),
      discountSummaryText: this.$store.state.config?.discount?.discountSummaryText,
      partnerName: this.$store.state.config?.discount?.discountNameDisplay,
    };
  },
  computed: {
    ...mapGetters({
      getBasket: 'basket/getBasket',
      getRatePlansDefault: 'config/getRatePlansDefault',
      isPercentageDiscount: 'config/isPercentageDiscount',
      isDiscountForOnlyOneProduct: 'config/isDiscountForOnlyOneProduct',
      priceKeyU30: 'config/getPriceKeyU30',
      priceKeyNormal: 'config/getPriceKeyNormal',
    }),
    partnerCodeActivated() {
      return this.$store.state.app?.partnerCode;
    },
    description() {
      if (this.isDiscountForOnlyOneProduct) return this.getMultiDescription();
      if (this.isAncestorsOfBasket()) return this.discountBasketDescription;
      return this.discountSummaryDescription;
    },
    isGreen() {
      return this.partnerCodeActivated && this.isPercentageDiscount;
    },
  },
  methods: {
    isAncestorsOfBasket() {
      // Maybe this is a bit ugly to hardcode the basket component name but well... ¯\_(ツ)_/¯
      return this.getAllComponentAncestors().includes('Basket');
    },
    getAllComponentAncestors() {
      const ancestors = [];
      let parent = this.$parent;

      while (parent.$options.name) {
        ancestors.push(parent.$options.name);
        parent = parent.$parent;
      }

      return ancestors;
    },
    getPartnerDiscountText() {
      const partnerSummaryText = this.discountSummaryText.replace('{discountName}', `${this.discountPercentage}%`);
      return partnerSummaryText;
    },
    isSomeonePorting() {
      return this.getBasket.items.some((item) => item.user.porting === 'keep-number');
    },
    getPartnerDiscountPrice() {
      let discountPrice = 0;
      if (!this.isDiscountAllowed) {
        return discountPrice;
      }

      if (this.isPercentageDiscount) {
        discountPrice = this.getBasket.totalFull - this.getBasket.total;
        // for each u30 item subtract the full price from the discount price to get only partner discount
        const u30Items = this.getBasket.items?.filter((item) => item.priceKey === this.priceKeyU30);
        if (u30Items?.length > 0) {
          u30Items.forEach((item) => {
            discountPrice -= item.priceFull - item.price;
          });
        }
      }
      return `-${discountPrice}${this.denotePostfix}`;
    },
    isDiscountAllowed() {
      // method for checking if there are any business logic rules to follow according giving discount
      if (!this.discountWithoutPorting) {
        // the user has to port their phonenumber for getting the discount
        if (!this.isSomeonePorting) {
          return false;
        }
      }
      return true;
    },
    getMultiDescription() {
      if (this.discountSummaryDescriptionMulti == '') return null;
      return this.discountSummaryDescriptionMulti.replace('{partnerName}', this.partnerName);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-membership {
  .summary-product {
    &__top {
      row-gap: 0; // TODO: reuse styling from .summary-products
    }
  }
}
</style>
