<template>
  <div class="date-picker" :class="fields.CssClass.value">
    <template v-if="loading">
      <LoadingSpinner class="loading" />
    </template>
    <template v-else>
      <template v-if="!isPorting && isEsim">
        <ScText class="date-picker__title text-m text-md-m" tag="h1" :field="fields.DatePickerTitleNotPorting" />
        <ScText class="date-picker__text text-md-s" tag="p" :field="fields.DatePickerNewNumberEsimText" />
      </template>
      <template v-else-if="isPorting || isEsim">
        <ScText class="date-picker__title text-m text-md-m" tag="h1" :field="fields.DatePickerTitle" />
        <ScText class="date-picker__text text-md-s" tag="p" :field="isEsim ? fields.DatePickerTextEsim : fields.DatePickerText" />

        <VueCalendar
          v-model="date"
          locale="nb"
          :masks="{ input: ['DD. MMMM YYYY'] }"
          color="green"
          :popover="{ visibility: 'focus' }"
          :theme="themeStyles"
          :min-date="minDate"
          :max-date="maxDate"
          :disabled-dates="bannedDates"
          :is-required="true"
          @input="changed($event)"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="calendar__content m-md-t-m">
              <input
                type="text"
                class="input-field__content-input text-m text-md-m"
                :id="fields.Key.value"
                :name="fields.Key.value"
                :value="inputValue"
                v-on="inputEvents"
                readonly="readonly"
              />
              <i class="icon icon-down"></i>
            </div>
          </template>
        </VueCalendar>
      </template>
      <template v-else>
        <ScText class="date-picker__title text-m text-md-m" tag="h1" :field="fields.DatePickerTitleNotPorting" />
        <ScText class="date-picker__text text-md-s" tag="p" :field="fields.DatePickerTextNotPorting" />
      </template>
    </template>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { getPortingDates } from '@/api';
import LoadingSpinner from './app/LoadingSpinner.vue';
import { gtmSharedParams } from '@/gtmTracking';

export default {
  name: 'DatePicker',
  components: {
    ScText: Text,
    VueCalendar: () => {
      // library isn't compatible with SSR, so it's skipped when SSR
      if (typeof window !== 'undefined') return import('v-calendar/lib/components/date-picker.umd');
    },
    LoadingSpinner,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    onEmitValue: {
      type: Function,
    },
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      date: this.$store.state.app[this.fields.Key.value] || null,
      themeStyles: {
        dayNotInMonth: 'not-in-month',
      },
      value: null,
      minDate: null,
      maxDate: null,
      bannedDates: null,
      loading: true,
    };
  },
  computed: {
    isPorting() {
      if (this.$store.state.basket?.usersCount) {
        // we are in a family flow
        const anyonePorting = this.$store.state.basket?.items.some((item) => item?.user?.porting === 'keep-number');
        return anyonePorting;
      }

      // otherwise we are in single or multi flow
      const currentItem = this.$store.state.basket?.currentItem
        ? this.$store.state.basket?.currentItem
        : this.$store.state.basket?.items[0];
      if (currentItem?.user?.porting === 'keep-number') return true;
      return false;
    },
    isEsim() {
      if (this.$store.state.basket?.usersCount) {
        // we are in a family flow
        const allUsersSelectedESim = this.$store.state.basket?.items.every((item) => item?.user?.simType === 'esim');
        return allUsersSelectedESim;
      }

      // otherwise we are in single or multi flow
      const currentItem = this.$store.state.basket?.currentItem
        ? this.$store.state.basket?.currentItem
        : this.$store.state.basket?.items[0];
      if (currentItem?.user?.simType === 'esim') return true;
      return false;
    },
  },
  beforeDestroy() {
    this.removeFromValidation(this);
    this.$root.$off('onSave');
  },
  async mounted() {
    this.$gtm.trackEvent({
      event: 'checkout_startdate',
      ...gtmSharedParams(),
    });
    this.addToValidation(this);

    this.$root.$on('onSave', () => {
      const date = this.date ? new Date(this.date).toISOString().slice(0, 10) : null;
      this.onSaveEvent(this.fields.Key.value, date, this.$options.name);
    });

    if (this.isPorting || this.isEsim) await this.loadPortingDates();
    else this.date = null;

    this.loading = false;

    this.$nextTick(() => {
      // TODO: investigate if this is still needed
      this.$root.$emit('onStartDateSet');
    });
  },
  methods: {
    async loadPortingDates() {
      try {
        const response = await getPortingDates(this.isEsim);

        if (!response.data) {
          console.error(`${this.$options.name}: No porting dates found`);
          return;
        }

        const { dateRange, notAllowedDates, allowedDates } = response?.data;
        this.bannedDates = notAllowedDates;
        this.minDate = dateRange?.firstDate;
        this.maxDate = dateRange?.lastDate;

        // check if the current set date (from store) is a allowed date
        const formattedDate = new Date(this.date).toISOString().slice(0, 10);
        if (!allowedDates.includes(formattedDate)) this.date = this.minDate;

        this.changed(this.date);
      } catch (error) {
        console.error(error);
      }
    },
    changed(e) {
      this.value = e;
      this.$emit('change', e);

      if (this.fields.Validations) this.$root.$emit('onValidate', this);
      this.onEmitValue(this, this.value);
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss">
.date-picker {
  &__title {
    margin-bottom: 20px;
    @include desktop {
      margin-bottom: 24px;
    }
  }
  .input-field__content-input {
    cursor: pointer;
  }
}
.loading {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.vc-popover-content-wrapper {
  max-width: 564px !important;
  width: Calc(#{100%} - #{32px}) !important;

  @include phone-xs {
    width: Calc(#{100%} - #{24px}) !important;
  }
  @include screen-tablet-portrait-up {
    max-width: 564px !important;
    width: 100%;
    width: Calc(#{100%} - #{32px}) !important;
  }
  @include desktop {
    max-width: 564px !important;
    width: 100%;
  }
  .vc-popover-content {
    color: black;
  }
  .vc {
    max-width: 100%;

    &-highlight {
      border-color: #{$color-green} !important;
      background: #{$color-green} !important;
      height: 32px !important;
      width: 32px !important;
    }
    &-day-content {
      color: #{$color-black} !important;
      font-size: 17.5px !important;

      &.is-disabled {
        color: #{$color-grey-light} !important;
      }
    }

    &-container {
      font-family: $font-normal !important;
    }

    &-weeks {
      padding-top: 16px !important;
      gap: 8px 0;
      padding: 8px 12px;
      @include screen-tablet-portrait-up {
        padding: 8px 24px;
      }
    }
    &-title {
      padding-top: 8px !important;
      text-transform: capitalize;
    }
    &-weekday {
      font-weight: bold !important;
      color: #000 !important;
    }
    &-header {
      margin-bottom: 8px;
      @include screen-tablet-portrait-up {
        margin-bottom: 16px;
      }
      .vc-title {
        font-family: $font-bold;
        color: #000 !important;
      }
    }
    &-arrows-container {
      margin-top: 8px !important;
      left: 0;
      .vc-arrow {
        color: black;
      }
    }
  }
}
.calendar {
  flex-direction: column;

  &__label {
    font-family: $font-bold;
    text-align: left;
    margin: 0 0 8px 24px;

    @include desktop {
      margin: 0 0 8px 0;
    }
  }

  &__content {
    margin: 24px 0;
    display: flex;
    align-items: center;
    position: relative;

    @include desktop {
      margin: 8px 0 24px;
    }

    i {
      position: absolute;
    }

    .icon {
      content: '';
      position: absolute;
      right: 16px;
      top: 15px;
      font-size: 24px;
    }

    &-input {
      background: #{$color-white};
      border: 1px solid #{$color-grey-dark};
      box-sizing: border-box;
      border-radius: 4px;
      height: 56px;
      width: 100%;
      display: block;
    }
  }
}

.vc-pane,
.vc-weeks,
.vc-pane-layout,
.vc-popover-content,
.vc-container {
  width: 100% !important;
}

.is-not-in-month {
  span {
    opacity: 0.3 !important;
  }
}
</style>
