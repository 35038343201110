<template>
  <div class="summary-content">
    <div class="summary-product" v-for="(productItem, i) in itemsToRender" :key="i">
      <transition name="slide" v-if="!isProductRemoved(i)" v-on:after-leave="afterLeave(i)">
        <div class="summary-product__top">
          <div class="summary-product__left flex flex-direction--column">
            <div class="summary-product__top--left flex justify-content--space-between">
              <div>
                <h5 class="text-m text-md-m">
                  <SummaryProductTitle :productItem="productItem" />
                </h5>
                <p v-if="showProductDescription(productItem)" class="text-s text-md-s m-b-xxs m-t-xxs">
                  {{ showProductDescription(productItem) }}
                </p>
              </div>
              <s v-if="isDiscount(productItem)" class="text-s text-md-s">{{ getProductFullPrice(productItem.product) }}</s>
            </div>
            <div class="summary-product__bottom">
              <span class="text-s text-md-s flex m-b-xxs">{{ $t('includes') }}:</span>
              <ul class="text-s text-md-s p-l-m">
                <li v-for="(spot, s) in productItem.product.fields.Spots" :key="s">
                  {{ spot.fields.ShortText.value !== '' ? spot.fields.ShortText.value : spot.fields.Text.value }}
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-direction--column summary-product__top--right">
            <strong class="text-m text-md-m" :class="{ discount: isDiscount(productItem) }">
              {{ getProductPriceText(productItem) }}
            </strong>

            <button
              class="button-textlink button-small transparent-btn"
              @click="!changeProductUrl ? removeProduct(productItem, i) : onRoute(productItem)"
            >
              <span class="main">
                <span class="text text-s text-normal text-md-m">{{ !changeProductUrl ? $t('remove') : $t('change') }}</span>
              </span>
            </button>
          </div>
        </div>
      </transition>

      <SummaryExtraSim
        class="flex justify-content--space-between"
        v-if="productItem.extraSim && productItem.extraSim > 0 && !isProductRemoved(i)"
        :product="productItem"
      />

      <transition name="fade">
        <div class="summary-product--removed" v-if="showProductRemovedFeedback(i)">
          <p class="text-m text-md-m">{{ $t('product-removed') }}</p>
          <button @click="undoRemove(i)" class="summary-product--removed__undo transparent-btn text-normal text-s text-md-s">
            {{ $t('undo') }}
          </button>
        </div>
      </transition>
    </div>

    <SummaryDigitalSecurity v-if="hasDigitalSecurity" />

    <transition name="expand">
      <div v-if="hasRecruitmentCode" class="summary-product__spacing summary-product__border">
        <div class="summary-product">
          <div class="summary-product__top">
            <div class="summary-product__top--left flex flex-direction--column">
              <h5 class="text-m text-md-m">{{ recruitmentProductTitle }}</h5>
              <p class="text-xs text-md-s m-t-xxs">{{ recruitmentProductDescription }}</p>
            </div>
            <i class="summary-product__top--right icon icon-present" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </transition>

    <SummaryMembership v-if="showSummaryMembership" />

    <div v-if="showFinalPrice" class="summary-product">
      <div class="flex justify-content--space-between">
        <span class="text-l text-md-l bold">{{ basketFinalText }}</span>
        <strong class="text-l text-md-l">{{ getFinalPriceText }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SummaryDigitalSecurity from './SummaryDigitalSecurity.vue';
import SummaryExtraSim from './SummaryExtraSim.vue';
import { getProductPriceFromRates } from '../../../../Talkmore.Web.Vue.Shared/src/utils';
import SummaryProductTitle from './SummaryProductTitle.vue';
import SummaryMembership from './SummaryMembership.vue';

export default {
  name: 'SummaryContent',
  components: {
    SummaryDigitalSecurity,
    SummaryExtraSim,
    SummaryProductTitle,
    SummaryMembership,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      basketFinalText: this.$store.state.config?.basketFinalText,
      denotePostfix: this.$t('price-denote') + this.$t('price-postfix'),
      removeTimeouts: {},
      removedItems: [],
    };
  },
  computed: {
    ...mapGetters({
      getBasket: 'basket/getBasket',
      getRatePlansDefault: 'config/getRatePlansDefault',
      usersCount: 'basket/getUsersCount',
      isPartnerOrderType: 'config/isPartnerOrderType',
      isPercentageDiscount: 'config/isPercentageDiscount',
      priceKeyU30: 'config/getPriceKeyU30',
    }),
    // needed because we want to deep-watch items inside getBasket
    basketItems() {
      return this.getBasket?.items ?? null;
    },
    recruitmentProductTitle() {
      return this.$store.state.config?.recruitmentCampaign?.productTitle;
    },
    recruitmentProductDescription() {
      return this.$store.state.config?.recruitmentCampaign?.productDescription;
    },
    finalPrice() {
      if (this.isEditorActive) return '757';
      return this.getBasket.total;
    },
    getFinalPriceText() {
      return this.finalPrice + this.denotePostfix;
    },
    hasRecruitmentCode() {
      return this.$store.state.app.recruitmentCode != null;
    },
    hasDigitalSecurity() {
      return this.$store.state.app.digitalSecurity;
    },
    isLastProductRemoved() {
      return this.$store.state.basket?.isLastProductRemoved ?? this.basketItems?.length === 0;
    },
    changeProductUrl() {
      return this.fields?.SummaryChangeProductStep?.url ?? null;
    },
    itemsToRender() {
      if (!this.basketItems) return null;
      return this.usersCount ? [this.basketItems[0]] : this.basketItems;
    },
    removedItemsIsEmpty() {
      return this.removedItems.length === 0;
    },
    showSummaryMembership() {
      return this.isPartnerOrderType && !this.isLastProductRemoved;
    },
    showFinalPrice() {
      return !this.isLastProductRemoved;
    },
  },
  watch: {
    removedItems(newVal) {
      if (newVal.length != this.basketItems?.length && !this.usersCount) this.setIsLastProductRemoved(false);
    },
  },
  mounted() {
    this.$root.$on('removeProductInstantly', this.removeProductsInstantly);
  },
  beforeDestroy() {
    this.$root.$off('removeProductInstantly', this.removeProductInstantly);
    this.$store.dispatch('basket/deleteItem', 'isLastProductRemoved');
  },
  methods: {
    hasUserInfo(productItem) {
      return productItem?.user?.info && productItem?.user?.firstname && productItem?.user?.lastname;
    },
    showProductDescription(productItem) {
      // in family flow show amount of users sharing the subscription
      if (this.usersCount) return this.$t('shared-by-x-persons').replace('{amount}', this.usersCount);
      // in other flows show user type and full name if available
      else if (this.hasUserInfo(productItem)) return `${this.getUserType(productItem)}: ${this.getFullName(productItem)}`;
      return false;
    },
    getFullName(productItem) {
      if (!this.hasUserInfo(productItem)) return null;
      return `${productItem.user.firstname} ${productItem.user.lastname}`;
    },
    getUserType(productItem) {
      if (!this.hasUserInfo(productItem)) return null;
      return productItem?.user?.info === 'owner' ? this.$t('owner-and-user') : this.$t('user');
    },
    isDiscount(product) {
      return product?.priceKey === 'U30';
    },
    getProductFullPrice(product) {
      return getProductPriceFromRates({ product, ratePlans: this.getRatePlansDefault }) + this.denotePostfix;
    },
    getProductPriceText(productItem) {
      let price = productItem.price;
      const product = productItem.product;
      if (this.usersCount) {
        const ratePlans = this.getRatePlansDefault;
        const multiplyBy = this.usersCount;
        const familyCrmItemPrice = this.$store.state.config.familyCrmItem?.price;
        price = getProductPriceFromRates({ product, ratePlans, multiplyBy, familyCrmItemPrice });
      } else if (this.isPercentageDiscount && productItem.priceKey !== this.priceKeyU30) {
        price = getProductPriceFromRates({ product, ratePlans: this.getRatePlansDefault }); // show full price if discount is only for one product
      }
      return price + this.denotePostfix;
    },
    removeProduct(productItem, i) {
      this.$set(
        this.removeTimeouts,
        i,
        setTimeout(() => {
          this.$root.$emit('onRemoveProduct', productItem);
          this.$delete(this.removeTimeouts, i);
          this.removedItems = this.removedItems.filter((item) => item !== i);
        }, 5000),
      );

      let value = false;
      if (
        this.basketItems?.length === 1 ||
        Object.keys(this.removeTimeouts).length === this.basketItems?.length ||
        this.usersCount // if family flow
      )
        // hide primary button if only one product is left or all products are removed
        value = true;
      this.setIsLastProductRemoved(value);
    },
    removeProductsInstantly() {
      if (Object.keys(this.removeTimeouts).length > 0) {
        const productsToRemove = Object.keys(this.removeTimeouts).map((key) => this.basketItems[key]);
        productsToRemove.forEach((product) => {
          this.$root.$emit('onRemoveProduct', product);
        });
        this.removedItems = [];
        Object.keys(this.removeTimeouts).forEach((key) => {
          this.undoRemove(key);
        });
      }
    },
    isProductRemoved(i) {
      return this.removeTimeouts[i] !== undefined;
    },
    undoRemove(i) {
      clearTimeout(this.removeTimeouts[i]);
      this.$delete(this.removeTimeouts, i);
      this.removedItems = this.removedItems.filter((item) => item !== i);
      if (this.usersCount) this.setIsLastProductRemoved(false);
    },
    afterLeave(i) {
      this.removedItems.push(i);
    },
    showProductRemovedFeedback(i) {
      if (this.removedItemsIsEmpty) return false;
      return this.removedItems.includes(i);
    },
    setIsLastProductRemoved(value) {
      this.$store.dispatch('basket/addItem', { key: 'isLastProductRemoved', value });
    },
    onRoute(basketItem) {
      this.$store.dispatch('basket/addCurrentItem', basketItem);
      if (this.changeProductUrl) this.$router.push(this.changeProductUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.summary-product__inner {
  padding: 16px; /* or your desired padding */
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to,
.expand-leave {
  max-height: 100px;
  opacity: 1;
}

.slide-leave-active {
  @include transition(0.8s);
}
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
  max-height: 80px;
}
.slide-leave {
  max-height: 250px;
}
// don't animate the fade out transition
.fade-leave-active {
  transition: none;
}
.fade-leave-to {
  opacity: 1;
}
.summary-content {
  width: 100%;
  overflow: hidden;
}

::v-deep .summary-product {
  border-bottom: 1px solid $color-grey-tint;
  padding: 16px 0;
  @include screen-tablet-portrait-up {
    padding: $spacing-m 0;
  }
  &:first-of-type {
    border-top: 1px solid $color-grey-tint;
  }
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__left {
    width: 100%;
    gap: $spacing-xxs;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    @include screen-tablet-portrait-up {
      column-gap: $spacing-m;
    }
    button {
      margin: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 0;
      margin-top: -13px;
      align-self: flex-end;
      margin-bottom: -4px;
    }

    &--left {
      width: 100%;
      gap: 0 $spacing-m;
      align-items: baseline;
    }
    &--right {
      @include screen-tablet-portrait-up {
        gap: $spacing-xxs;
      }
      strong {
        white-space: nowrap;
        &.discount {
          color: $color-green-darker;
        }
      }
    }

    .icon {
      display: flex;
      font-size: 30px;
      justify-content: flex-end;
      @include screen-tablet-portrait-up {
        font-size: 32px;
      }
    }
  }

  @include screen-tablet-portrait-up {
    margin-bottom: 0;
  }

  &__input-field {
    display: inline-block;
    margin-top: 8px;
    width: 100%;
    button {
      padding-left: 12px;
      padding-right: 12px;
      margin-right: -8px;
      margin-left: 0;
    }
  }
  .recruitment-message {
    &--error {
      color: $color-red;
    }
    &--success {
      color: $color-green-darker;
    }
  }

  &--removed {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxs;
    align-content: flex-start;

    &__undo {
      width: fit-content;
    }
  }
}
// nessecary for smooth transform animation, since the animation can't be set directly on summary-product div
.summary-product__spacing {
  .summary-product {
    padding: $spacing-m 0;
  }
}
.summary-product__border {
  .summary-product {
    border-top: none;
    border-bottom: 1px solid $color-grey-tint;
  }
}
</style>
