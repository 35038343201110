<template>
  <div class="recruitment" v-if="visible">
    <div class="check-box">
      <input type="checkbox" id="recruitment" name="recruitment" v-model="checkboxValue" class="check-box__input" />
      <div class="check-box__content">
        <ScText
          for="recruitment"
          class="check-box__content-label text-bold text-s text-md-s p-l-xxs"
          tag="label"
          :field="fields.RecruitmentCheckboxText"
        />
      </div>
    </div>

    <div
      class="form-field form-field--split recruitment__input-field input-field--small"
      :class="[checkboxValue == true ? 'isOpen' : 'isClosed', recruitmentCodeActivated == true ? 'code-active' : '']"
    >
      <div class="form-field__input form-field--small m-b--clear p-b-xs" v-if="!recruitmentCodeActivated">
        <ScText for="recruitmentcode" tag="label" :field="fields.RecruitmentLabelText" class="text-s text-md-s" />
      </div>

      <div class="form-field__input input-field__content" v-if="!recruitmentCodeActivated">
        <input
          inputmode="text"
          autocapitalize="none"
          autocorrect="off"
          id="recruitmentcode"
          name="recruitmentcode"
          placeholder=""
          type="text"
          class="input-field__content-input text-s text-md-s"
          :disabled="isBanned"
          v-model.trim="code"
          :class="[showCodeError ? 'field-error' : '']"
          @keydown.enter="addCode"
        />

        <Spinner v-if="loading" class="recruitment--loading flex justify-content--center"></Spinner>
        <button
          v-else
          type="button"
          class="button-textlink button-small button-hover"
          :class="{ disabled: !code || isBanned }"
          @click="addCode"
          @keydown.enter.prevent="addCode"
        >
          <span class="main">
            <ScText class="text-normal text-sm-s text-md-m" tag="span" :field="fields.RecruitmentSubmitText" />
          </span>
        </button>
      </div>

      <div v-if="recruitmentCodeActivated" class="recruitment-message__container">
        <ScText
          class="recruitment-message recruitment-message--success text-bold text-sm-s text-md-s"
          :field="fields.RecruitmentCodeActivatedText"
        />
        <p class="recruitment-message recruitment-message--success text-sm-s text-md-s">{{ code }}</p>
        <button class="button-textlink button-small button-hover" :style="'margin-left: auto'" @click="removeCode">
          <span class="main">
            <ScText class="text-normal text-sm-s text-md-m" tag="span" :field="fields.RecruitmentRemoveText" />
          </span>
        </button>
      </div>
    </div>

    <p
      v-if="showCodeError && checkboxValue"
      class="recruitment-message--error text-xs text-md-xs validation-text validation-error-text text-s"
    >
      {{ errorText }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateRecruitment } from '@/api';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams } from '@/gtmTracking';
import { gtmAddCouponEvent } from '../../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import { Spinner } from '@/sharedComponents';

export default {
  name: 'Recruitment',
  components: {
    ScText: Text,
    Spinner,
  },
  inject: {
    saveRecruitmentCode: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      checkboxValue: this.$store.state.app.recruitmentCode != null,
      recruitmentCodeActivated: this.$store.state.app.recruitmentCode != null,
      code: this.$store.state.app.recruitmentCode,
      showCodeError: false,
      showCodeSuccess: false,
      loading: false,
      errorText: this.fields.RecruitmentCodeEmptyErrorText?.value,
      retryTime: 300000, // api sets a 5 minutes cooldown for retrying a new recruitmentcode
    };
  },
  computed: {
    ...mapGetters({
      isRecruitmentOrderType: 'config/isRecruitmentOrderType',
    }),
    isSomeonePorting() {
      let isPorting = false;
      const items = this.$store.getters['basket/getItems'];
      items.forEach((item) => {
        if (item.user?.porting === 'keep-number') isPorting = true;
      });
      return isPorting;
    },
    isRecruitmentFlowStarted() {
      return this.$store.state.app?.isRecruitmentFlowStarted;
    },
    visible() {
      return this.isSomeonePorting && !this.isRecruitmentOrderType;
    },
    recruitmentRetryTimestamp() {
      return this.$store.state.app?.recruitmentRetryTimestamp;
    },
    isBanned() {
      return typeof this.recruitmentRetryTimestamp !== 'undefined';
    },
  },
  watch: {
    checkboxValue() {
      if (this.checkboxValue === false) {
        this.removeCode();
      }
    },
    showCodeError(newVal) {
      if (newVal === true) this.$root.$emit('onErrorMessage', this.errorText);
    },
    recruitmentRetryTimestamp: {
      immediate: true,
      handler(value) {
        if (value) {
          const intervalId = setInterval(() => {
            if (Date.now() > this.recruitmentRetryTimestamp) {
              this.$store.dispatch('app/deleteItem', 'recruitmentRetryTimestamp');
              console.log(`${this.$options.name}: Cleared value for key: 'recruitmentRetryTimestamp'`);
              this.showCodeError = false;
              this.errorText = this.fields.RecruitmentCodeEmptyErrorText?.value;

              // Clear the interval so it stops running
              clearInterval(intervalId);
            }
          }, 1000);
        }
      },
    },
  },
  mounted() {
    if (!this.isSomeonePorting && this.code && !this.isRecruitmentFlowStarted) this.removeCode(); // TODO: clarify if the code should be removed
    if (this.isBanned) {
      this.showCodeError = true;
      this.errorText = this.fields?.RecruitmentCodeBlockedErrorText?.value;
    }
  },
  methods: {
    addCode() {
      if (!this.code) {
        this.showCodeError = true;
        this.showCodeSuccess = false;
        return;
      }
      this.checkRecruitmentCode();
    },
    async checkRecruitmentCode() {
      this.loading = true;
      let isValid = false;

      if (this.code.startsWith('0047')) this.code = this.code.substring(4);
      if (this.code.startsWith('+47')) this.code = this.code.substring(3);

      // remove all whitespaces (sometimes user will write phone numbers like this "987 87 891")
      this.code = this.code.replace(/\s+/g, '');

      try {
        const payload = {
          Code: this.code,
          Token: this.$store.state.app.oidcToken,
          ConfigId: this.$store.state.app.configId,
        };
        const response = await validateRecruitment(payload);
        if (response.data) isValid = response.data.IsValid;
        if (!isValid) this.errorText = this.fields?.RecruitmentCodeErrorText?.value;
      } catch (error) {
        if (error.response.status === 403) {
          this.errorText = this.fields?.RecruitmentCodeErrorText?.value;
          console.log('Recruitment - Error: OIDC token has expired');
        } else if (error.response.status === 429) {
          if (!this.$store.state.app?.recruitmentRetryTimestamp) {
            this.code = null;
            const timeStamp = Date.now() + this.retryTime;
            this.$store.dispatch('app/addItem', { key: 'recruitmentRetryTimestamp', value: timeStamp });
            console.log(`${this.$options.name}: Saved value: '${timeStamp}' for key: 'recruitmentRetryTimestamp'`);
          }
          this.errorText = this.fields?.RecruitmentCodeBlockedErrorText?.value;
          console.log('Recruitment - Error: Too many requests');
        } else {
          this.errorText = this.fields?.RecruitmentCodeErrorText?.value;
          console.log('Recruitment - Error: Generic error');
        }
      } finally {
        this.showCodeError = !isValid;
        this.showCodeSuccess = isValid;
        this.recruitmentCodeActivated = isValid;

        if (isValid) {
          await this.saveRecruitmentCode(this.code);
          gtmAddCouponEvent({ coupon: this.code, gtmSharedParams: gtmSharedParams() });
        }

        this.loading = false;
      }
    },
    removeCode() {
      this.showCodeSuccess = false;
      this.recruitmentCodeActivated = false;
      this.code = null;
      this.showCodeError = false;
      this.$store.dispatch('app/deleteItem', 'recruitmentCode');
      console.log(`${this.$options.name}: Cleared value for key: 'recruitmentCode'`);
    },
  },
};
</script>

<style lang="scss" scoped>
.recruitment {
  padding-top: $spacing-s;
  margin-top: $spacing-s;
  border-top: 1px solid $color-grey-tint;

  @include screen-tablet-portrait-up {
    padding-top: $spacing-m;
    margin-top: $spacing-m;
  }

  &--loading {
    min-width: 65px;
  }

  .check-box {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  &__input-field {
    display: flex;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0;
    height: 0;
    overflow-y: clip;
    overflow-x: visible;
    flex-direction: column;

    &.isOpen {
      visibility: visible;
      height: 108px;
      transition: ease-in-out all 0.25s;
      padding-top: $spacing-s;
      @include desktop {
        height: 118px;
      }
      &.validate-error {
        height: auto !important;
        overflow: visible;
      }
      // can't use validate error for this, since we don't want that on the outer div. The label text should not be red.
      input.field-error:not(:disabled) {
        @include inputErrorEffect;
      }
    }

    &.isClosed {
      visibility: hidden;
      height: 0;
      transition: ease-in-out all 0.25s;
    }

    &.code-active {
      height: auto;
    }
  }

  .recruitment-message {
    &--error {
      color: $color-red;
      padding-top: $spacing-xxs;
    }

    &--success {
      color: $color-green-darker;
    }

    &__container {
      display: flex;
      gap: 3px;
      align-items: center;
      padding-top: $spacing-xxs;

      button {
        min-width: 65px;
        padding-right: 12px;
        margin: -8px -12px -8px 0;
      }
    }
  }
}
</style>
