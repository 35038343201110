<template>
  <div v-if="showDiscountField" class="membership m-t-s m-md-t-m p-t-s p-md-t-m">
    <div
      :class="{ 'validate-error': showCodeError, 'validate-success': partnerCodeActivated }"
      class="form-field form-field--split form-field--small"
    >
      <div class="form-field__description">
        <div class="form-field__description-head">
          <label v-if="label" for="membership" class="text-s text-sm-s text-md-s bold">{{ label }}</label>
        </div>

        <span v-if="labelDescription" class="form-field__description-text text-s text-sm-s text-md-s">
          {{ labelDescription }}
        </span>
      </div>

      <div class="form-field__input input-field--small">
        <div class="flex flex-1 position-relative">
          <input
            inputmode="text"
            autocapitalize="none"
            autocorrect="off"
            id="membership"
            name="membership"
            placeholder=""
            type="text"
            :maxlength="fieldLength"
            v-model.trim="code"
            :disabled="partnerCodeActivated"
            @keydown.enter.prevent="addCode"
          />
          <i v-if="partnerCodeActivated" class="validation-check icon icon-check"></i>
        </div>

        <button
          v-if="!partnerCodeActivated"
          type="button"
          class="button-textlink button-small button-hover"
          :class="{ disabled: !code }"
          @click="addCode"
          @keydown.enter.prevent="addCode"
        >
          <span class="main">
            <span class="text-normal text-sm-s text-md-m">{{ $t('activate') }}</span>
          </span>
        </button>

        <button
          v-else
          type="button"
          class="button-textlink button-small button-hover"
          @click="removeCode"
          @keydown.enter.prevent="removeCode"
        >
          <span class="main">
            <span class="text-normal text-sm-s text-md-m">{{ $t('remove') }}</span>
          </span>
        </button>
      </div>

      <div class="form-field__feedback-container">
        <span class="text-xs text-md-xs validation-text p-t--clear" v-if="showCodeError">{{ firstValidationError }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { gtmSharedParams } from '@/gtmTracking';
import { gtmAddCouponEvent } from '../../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'Membership',
  inject: {
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
  },
  data() {
    return {
      validationErrors: [],
      code: this.$store.state.app.partnerCode,
      hasDiscount: this.$store.state.config?.discount != null,
      fieldLength: this.$store.state.config?.discount?.discountFieldLength,
      label: this.$store.state.config?.discount?.discountFieldText,
      labelDescription: this.$store.state.config?.discount?.discountFieldDescription,
      fields: {
        Validations: this.$store.state.config?.discount?.discountValidations,
      },
    };
  },
  computed: {
    showDiscountField() {
      return this.hasDiscount;
    },
    partnerCodeActivated() {
      return this.$store.state.app.partnerCode != null;
    },
    showCodeError() {
      return this.validationErrors.length !== 0;
    },
    firstValidationError() {
      return this.validationErrors[0]?.text;
    },
  },
  beforeDestroy() {
    this.removeFromValidation(this);
  },
  mounted() {
    this.addToValidation(this);

    // this.$root.$on('onValidated', (data) => {
    //   console.log('mounted: onValidated called', data);
    // });

    // this.$root.$on('onInvalidateAll', (data) => {
    //   console.log('mounted: onInvalidateAll called', data);
    // });

    // this.$root.$on('onInvalidated', (data) => {
    //   console.log('mounted: onInvalidated called', data);
    // });
  },
  methods: {
    removeCode() {
      this.$store.dispatch('app/deleteItem', 'partnerCode');
      console.log(`${this.$options.name}: Cleared value for key: 'partnerCode'`);
      this.code = null;
      if (this.fields.Validations) this.$root.$emit('onValidate', this);
    },
    addCode() {
      if (this.fields.Validations) this.$root.$emit('onValidate', this);

      if (this.code && !this.showCodeError) {
        this.$store.dispatch('app/addItem', { key: 'partnerCode', value: this.code });
        console.log(`${this.$options.name}: Saved value: '${this.code}' for key: 'partnerCode'`);
        gtmAddCouponEvent({ coupon: this.code, gtmSharedParams: gtmSharedParams() });
      }
    },
    // used by Talkmore.Web.Vue.Shared/validate
    getValue() {
      return this.code;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    validated(valid, validation, validationError) {
      if (!valid && (this.getValue()?.length || validationError)) {
        if (this.validationErrors.some((error) => error.name === validation.fields.Key.value)) return;

        this.validationErrors.push({ name: validation.fields.Key.value, text: validation.fields.ErrorText.value });
      } else {
        this.validationErrors = this.validationErrors.filter((error) => error.name !== validation.fields.Key.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.membership {
  border-top: 1px solid $color-grey-tint;

  .form-field__feedback-container {
    padding: $spacing-xxs 0 0;
  }

  .validation-check {
    position: absolute;
    top: 0;
    right: 8px;
    height: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 23px;
    color: $color-green-darker;
  }
}
</style>
