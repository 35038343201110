<template>
  <div class="terms-private desktop-wide text-s text-md-s">
    <ScText tag="h5" class="terms-private__title text-l text-md-m" :field="fields.TermsTitle" />

    <ScText tag="p" class="terms-private__text" :field="fields.TermsText" />

    <div class="terms-private__content">
      <CheckBox class="terms-private__content-checkbox" :fields="fields" :embedded="true" @change="changed">
        <!-- TODO: Fix accessability for this a-tag, empty href links are not accessable -->
        <ScText
          tag="a"
          tabindex="0"
          class="terms-private__content-link link"
          @click="openOverlay"
          @keydown.enter.space.prevent="openOverlay"
          :field="fields.TermsLinkText"
        />
      </CheckBox>
    </div>

    <BottomSheet ref="termsBottomSheet" :closeText="fields.BottomSheetCloseText.value">
      <ScText class="text-m text-md-m" tag="h1" :field="fields.BottomSheetTitle" />

      <ScRichText tag="div" :field="fields.BottomSheetText" />

      <div class="m-t-s flex justify-content--center">
        <ScText
          class="primary-xs-small primary-md-default primary-btn"
          tag="button"
          :field="fields.BottomSheetCloseText"
          @click="closeOverlay"
        />
      </div>
    </BottomSheet>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { CheckBox } from '@/sharedComponents';
import BottomSheet from './util/BottomSheet.vue';
import { gtmSharedParams } from '@/gtmTracking';
import { gtmModalViewEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'Terms',
  components: {
    ScText: Text,
    CheckBox,
    BottomSheet,
    ScRichText: RichText,
  },
  inject: {
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      value: this.$store.state.app[this.fields.Key.value],
    };
  },
  beforeDestroy() {
    this.removeFromValidation(this);
  },
  mounted() {
    this.addToValidation(this);
  },
  methods: {
    openOverlay() {
      this.$refs.termsBottomSheet.open();

      const modal_name = this.fields.TermsTitle?.value;
      gtmModalViewEvent({ modal_name, gtmSharedParams: gtmSharedParams() });
    },
    closeOverlay() {
      this.$refs.termsBottomSheet.close();
    },
    changed(e) {
      this.value = e.target.checked;

      if (this.fields.Validations) this.$root.$emit('onValidate', this);
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-private {
  margin-bottom: 24px;

  @include screen-tablet-portrait-up {
    padding: 0 16px 0;
  }

  &__title {
    padding-bottom: 16px;
    @include screen-tablet-portrait-up {
      padding-bottom: 12px;
    }
  }

  &__text {
    @include screen-tablet-portrait-up {
      padding-bottom: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;

    &-checkbox {
      align-items: center;
      margin: 0;
      margin-top: 16px;
      @include screen-tablet-portrait-up {
        margin-top: 0;
      }
    }

    &-link {
      color: #{$color-black};
      width: fit-content;
      margin-top: 8px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
