<template>
  <!-- this fixes all ssr hydration errors -->
  <div v-if="visible" class="layout">
    <!-- <VisitorIdentification /> -->

    <RouteLoading v-if="isLoading" />

    <template v-else-if="hasStepsContainer">
      <StepsContainer>
        <Placeholder class="container" name="Main" :rendering="route" />
      </StepsContainer>
    </template>

    <template v-else>
      <Placeholder class="container" name="Main" :rendering="route" />
      <Placeholder v-if="showSecondaryPlaceholder" class="wide-container" name="Secondary" :rendering="route" />
    </template>

    <Footer v-if="!isLoading" :classes="'m-t-4xl'">
      <template v-slot:logo>
        <Logo :imgSrc="imageLogo" :mobileLogoPosition="'center'" altText="Talkmore Privat" />
      </template>
    </Footer>
  </div>
</template>

<script>
// import VisitorIdentification from './VisitorIdentification';
import { Logo, Footer } from '@/sharedComponents';
import logoImage from '@/assets/img/logo.svg';
import StepsContainer from '@/components/app/StepsContainer.vue';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { validate } from '../../../../Talkmore.Web.Vue.Shared/src/validate';
import gqlConfig from '@/api/graphQL/getConfig';
import RouteLoading from './RouteLoading.vue';
import config from './../../temp/config';

export default {
  name: 'Layout',
  components: {
    // VisitorIdentification,
    StepsContainer,
    Placeholder,
    RouteLoading,
    Logo,
    Footer,
  },
  apollo: {
    item: {
      query: gqlConfig,
      variables() {
        return {
          path: this.configPath,
        };
      },
      skip() {
        // We first want to fetch sitecore context before firing the query
        return true;
      },
      async result(result) {
        // Once we got the config from apollo we want to save it to state
        await this.$store.dispatch('config/addInitialConfig', { key: 'config', value: result.data.item });
        this.isLoading = this.$store.state.config.loading;
      },
    },
  },
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      configPath: this.$store.state.app?.configId,
      visible: false,
      imageLogo: logoImage,
      isLoading: this.$store.state.config?.loading,
    };
  },
  computed: {
    showSecondaryPlaceholder() {
      return this.route?.placeholders?.Secondary?.length !== 0;
    },
    reloadConfig() {
      return this.$store.state.config.reloadConfig;
    },
    hasStepsContainer() {
      // check if the current orderflow even has steps pages implemented or not
      if (!this.$store.state.config.stepsPages) return false;

      let result = false;

      this.$store.state.config.stepsPages.forEach((step) => {
        const trimmedPath = this.$route.path.toLowerCase().replace(config.appBasePath, '');
        if (trimmedPath.includes(`/${step.name.toLowerCase()}`)) result = true;
      });

      return result;
    },
  },
  watch: {
    reloadConfig: {
      immediate: true,
      async handler(shouldReload) {
        if (!shouldReload) return;

        const newConfigId = this.$jss.store.state.sitecoreContext.PrivateOrderflowContext.configId;
        console.log(`${this.$options.name}: Old config id: ${this.configPath}`);
        console.log(`${this.$options.name}: New config id: ${newConfigId}`);

        console.log(`${this.$options.name}: Resetting state...`);
        await this.$store.dispatch('app/clearState');
        await this.$store.dispatch('basket/clearState');
        await this.$store.dispatch('config/clearState');

        await this.saveConfigId(newConfigId);
        await this.saveBasePath();

        // check if we saved trumf id for partner code
        if (typeof window !== 'undefined') {
          const trumfPartnerCode = window.localStorage.getItem('TrumfPartnerCode');
          if (trumfPartnerCode) {
            await this.$store.dispatch('app/addItem', { key: 'partnerCode', value: trumfPartnerCode }, { root: true });
            console.log(`Store: Saved value: '${trumfPartnerCode}' for key: 'partnerCode'`);

            window.localStorage.removeItem('TrumfPartnerCode');
            console.log(`Store: Removed value: 'TrumfPartnerCode' from local storage`);
          }
        }

        // now that we have set the correct config path we can fire the graphql query
        await this.$apollo.queries.item.refetch({ path: newConfigId });
        this.$apollo.queries.item.skip = false;

        this.$store.dispatch('config/addItem', { key: 'reloadConfig', value: false });
      },
    },
  },
  async created() {
    if (this.$store.state.app.configId) return;

    const configId = this.$jss.store.state.sitecoreContext.PrivateOrderflowContext.configId;

    // if we get redirected back from oidc login and land on a redirect page containing the OidcRedirect.vue
    // component, we will not have a config so we need to read it from localStorage where it was saved to before
    if (!this.$store.state.app?.configId) {
      console.log(`${this.$options.name}: No config id in store!`);
      const storageConfigId = typeof window === 'undefined' ? null : localStorage.getItem('ConfigId');
      this.configPath = configId || storageConfigId;
      console.log(`${this.$options.name}: Set config path value: '${this.configPath}'`);
      await this.saveConfigId(this.configPath);
    }

    await this.saveBasePath();

    // now that we have set the correct config path we can fire the graphql query
    this.$apollo.queries.item.skip = false;
  },
  beforeDestroy() {
    // TODO: evaluate if needed
    // this.$root.$off('onValidate');
    // this.$root.$off('onInvalidateAll');
  },
  mounted() {
    this.$root.$on('onValidate', (data) => {
      this.onValidate(data, false);
    });

    this.$root.$on('onInvalidateAll', (data) => {
      this.onValidate(data, true);
    });

    this.$nextTick(() => {
      this.onValidate();

      // always scroll the placeholder into view if we are showing StepContainer component
      if (this.hasStepsContainer) {
        const placeholder = document.querySelector('.container');
        if (placeholder) placeholder.parentElement.scrollIntoView({ behavior: 'smooth' });
      }
    });

    this.visible = true;
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: 'no',
      },
      title:
        (this.route.fields && this.route.fields.pageTitle && this.route.fields.pageTitle.value) || this.$t('default-page-title'),
      meta: [
        {
          name: 'description',
          content: this.$t('default-page-description'),
        },
      ],
    };
  },
  methods: {
    onValidate(ref, validationError) {
      if (ref !== undefined && ref.$vnode.tag === undefined) return;
      if (validate(ref, validationError)?.valid) {
        this.$root.$emit('onValidated', null);
      } else this.$root.$emit('onInvalidated', null);
    },
    async saveBasePath() {
      // we can detect if user switches to a different type of the same orderflow based on the base path
      const basePath = this.$jss.store.state.sitecoreContext.PrivateOrderflowContext.basePath;
      await this.$store.dispatch('app/addItem', { key: 'basePath', value: basePath });
      console.log(`${this.$options.name}: Saved value: '${basePath}' for key: 'basePath'`);
    },
    async saveConfigId(configId) {
      await this.$store.dispatch('app/addItem', { key: 'configId', value: configId });
      console.log(`${this.$options.name}: Saved value: '${configId}' for key: 'configId'`);
    },
  },
};
</script>

<style lang="scss">
.layout {
  min-height: calc(100vh - 72px); // substract height of the Logo from 100vh TODO: dynamically calculate Logo height
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: auto;

  .container {
    width: 100%;
    margin-bottom: 80px;
    margin-left: 16px;
    margin-right: 16px;
    width: Calc(100% - #{32px});

    @include phone-xs {
      margin-left: 12px;
      margin-right: 12px;
      width: Calc(100% - #{24px});
    }

    @include desktop {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 24px;
      margin-right: 24px;
      width: Calc(100% - #{48px});
    }
  }

  .wide-container {
    width: 100%;
  }
}

// Hides Sitecore Experience Editor markup, if you run the app in
// connected mode while the Sitecore cookies are set to edit mode.
.scChromeData,
.scpm {
  display: none !important;
}

.experience-editor {
  padding: 8px;
  border: 1px dashed orange;
  margin: 8px 0;
  max-width: 370px;

  a {
    cursor: pointer;
    border-bottom: 1px dashed #{$color-red};
    color: #{$color-red};
  }

  div {
    background: #{$color-green-light};
    width: 100%;
    padding: 16px;
    border-radius: 4px;
  }
}
</style>
