<template>
  <div id="root">
    <Logo :imgSrc="imageLogo" class="app-logo" altText="Talkmore Privat" />

    <transition name="fade">
      <router-view v-if="!languageIsChanging" />
    </transition>

    <Basket />

    <div v-if="languageIsChanging">Language is changing...</div>

    <Dev v-if="debugEnabled" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import { Logo } from '@/sharedComponents';
import logoImage from '@/assets/img/logo.svg';
import Dev from '@/components/app/Dev';
import Basket from '@/components/Basket';
import { toggleBodyScrollLock, calculateAge } from '../../Talkmore.Web.Vue.Shared/src/utils';
import { subscribeToValidation, clearValidations, unsubscribeFromValidation } from '../../Talkmore.Web.Vue.Shared/src/validate';
import { track } from '@/lib/SitecoreTracking';
import { registerOrder } from '@/api';
import {
  gtmSharedParams,
  getGtmItems,
  gtmCartActionEvent,
  getGtmItem,
  gtmAddToCartEvent,
  gtmRemoveFromCartEvent,
} from '@/gtmTracking';
import {
  gtmErrorMessageEvent,
  gtmPurchaseEvent,
  removeDuplicateGtmItems,
} from '../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'AppRoot',
  components: {
    Logo,
    Dev,
    Basket,
  },
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      changeAppLanguage: this.changeAppLanguage,
      onSaveEvent: this.onSaveEvent,
      onEmitValue: this.onEmitValue,
      addToValidation: this.addToValidation,
      removeFromValidation: this.removeFromValidation,
      isEditorActive: this.isEditorActive,
      trackEvents: this.trackEvents,
      emitInputValue: this.emitInputValue,
      onGoToPreviousStep: this.onGoToPreviousStep,
      saveRecruitmentCode: this.saveRecruitmentCode,
      saveRecruiterName: this.saveRecruiterName,
    };
  },
  data() {
    return {
      languageIsChanging: false,
      loading: false,
      isEditorActive: isExperienceEditorActive(),
      imageLogo: logoImage,
      eventListeners: [
        { event: 'onCreateOrder', handler: this.onCreateOrderEvent },
        { event: 'onSaveMultipleProducts', handler: this.onSaveMultipleProducts },
        { event: 'onSaveSingleProduct', handler: this.onSaveSingleProduct },
        { event: 'onRemoveProduct', handler: this.onRemoveProduct },
        { event: 'onGoToPreviousStep', handler: this.onGoToPreviousStep },
        { event: 'onMessageBoxAlert', handler: this.onErrorMessage },
        { event: 'onErrorMessage', handler: this.onErrorMessage },
        { event: 'onUpdateBasketItemProduct', handler: this.onUpdateBasketItemProduct },
        { event: 'onHandleInfoData', handler: this.onHandleInfoData },
        { event: 'onSavePortingKeepNumber', handler: this.savePortingKeepNumber },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getItems: 'basket/getItems',
      getRatePlansDefault: 'config/getRatePlansDefault',
      getRatePlansU30: 'config/getRatePlansU30',
      getRatePlansByPriceKey: 'config/getRatePlansByPriceKey',
      usersCount: 'basket/getUsersCount',
      priceKey: 'config/getPriceKey',
      priceKeyDefault: 'config/getPriceKeyDefault',
      isDiscountForOnlyOneProduct: 'config/isDiscountForOnlyOneProduct',
      basket: 'basket/getBasket',
      getItemsCountByProductAndPriceKey: 'basket/getItemsCountByProductAndPriceKey',
      getCoupon: 'config/getCoupon',
      priceKeyNormal: 'config/getPriceKeyNormal',
      priceKeyU30: 'config/getPriceKeyU30',
      priceKeyPartner: 'config/getPriceKeyPartner',
    }),
    debugEnabled() {
      return process.env.NODE_ENV === 'development';
    },
  },
  watch: {
    $route() {
      clearValidations();
    },
  },
  beforeDestroy() {
    this.eventListeners.forEach(({ event }) => this.$root.$off(event));
    toggleBodyScrollLock(false);
  },
  mounted() {
    this.eventListeners.forEach(({ event, handler }) => this.$root.$on(event, (...args) => handler(...args)));
  },
  methods: {
    changeAppLanguage(language) {
      // Changing languages is an async action, therefore the `languageIsChanging` property can be used
      // to show a loading/switching screen when language is being changed.
      const i18n = this.$i18n.i18next;
      if (i18n.language !== language) {
        this.languageIsChanging = true;

        i18n.changeLanguage(language, () => {
          this.languageIsChanging = false;
        });
      }
    },
    onSaveEvent(key, value, trigger) {
      // we do not want to save undefined values
      if (value === undefined) return;

      // we only want to keep non-null key-value pairs in store
      if (value === null) {
        this.$store.dispatch('app/deleteItem', key);
        console.log(`${trigger}: Removed key: '${key}' since it's value is null`);
        this.$root.$emit('onSaved');
        return;
      }

      // only save item if it differs from current store
      if (this.$store.state.app[key] === value) return;

      this.$store.dispatch('app/addItem', { key, value });
      if (typeof value === 'object') value = JSON.stringify(value);
      // console.log(`${trigger}: Saved value: '${value}' for key: '${key}'`);

      this.$root.$emit('onSaved');
    },
    onRemoveProduct(item) {
      let result;
      const product = item.product;
      const price = item.price;
      const coupon = item.coupon;
      if (this.usersCount) {
        this.$store.commit('basket/CLEAR_BASKET_ITEMS'); // family flow - remove all items
      } else {
        if (this.isDiscountForOnlyOneProduct)
          result = this.handleDiscountForOnlyOneProduct({ product: item.product, isAdding: false, basketItem: item }); // FIXME: handle discount if there are u30 items
        this.$store.dispatch('basket/removeBasketItem', item);
      }
      console.log(`${this.$options.name}: Removed item: '${product.displayName}' from basket store`);
      if (this.isDiscountForOnlyOneProduct && result?.updatedItem) {
        // combine items to track together
        const { removedItem, addedItem } = this.getGtmUpdatedProductItems(
          result.updatedItem.product,
          result.priceKeyNew,
          result?.updatedItem.priceKey,
        );
        const removedItems = [removedItem, getGtmItem({ product, price, isAdding: false, coupon })];
        const [uniqueRemovedItems, uniqueAddedItems] = removeDuplicateGtmItems(removedItems, [addedItem]);
        if (uniqueRemovedItems.length > 0) gtmRemoveFromCartEvent(uniqueRemovedItems);
        if (uniqueAddedItems.length > 0) gtmAddToCartEvent(uniqueAddedItems);
        return;
      }
      const quantity = this.usersCount ? this.usersCount - 1 : null;
      gtmCartActionEvent({ product, price, coupon, quantity, isAdding: false });
    },
    async onSaveMultipleProducts(product, quantityNormal, quantityU30 = null) {
      const defaultPrice = this.findPrice(product, this.getRatePlansByPriceKey(this.priceKeyDefault));
      const basketItemNormal = {
        product,
        priceKey: this.isDiscountForOnlyOneProduct ? this.priceKeyNormal : this.priceKeyDefault,
      }; // Default to normal price when discount is for only one product

      let defaultCount = this.isDiscountForOnlyOneProduct
        ? this.getItemsCountByProductAndPriceKey(product, this.priceKeyNormal) // Count normal price items when discount is for only one product
        : this.getItemsCountByProductAndPriceKey(product, this.priceKeyDefault);
      const u30Count = this.getItemsCountByProductAndPriceKey(product, this.priceKeyU30);

      if (this.isDiscountForOnlyOneProduct) {
        const partnerCount = this.getItemsCountByProductAndPriceKey(product, this.priceKeyPartner);
        defaultCount += partnerCount; // Include partner items in the default count
        defaultCount += u30Count; // Include u30 items in the default count
      }
      const resultNormal = this.handleQuantityChange(basketItemNormal, defaultCount, quantityNormal, defaultPrice);
      let resultU30 = null;
      const items = [];
      if (resultNormal) {
        const quantity = resultNormal.quantity;
        const isAdding = resultNormal.isAdding;
        const item = getGtmItem({ product, price: defaultPrice, quantity, isAdding });
        items.push(item);
      }
      if (quantityU30 !== null) {
        const coupon = this.getCoupon(this.priceKeyU30);
        const basketItemU30 = { product, priceKey: this.priceKeyU30 };
        const u30Price = this.findPrice(product, this.getRatePlansU30);
        resultU30 = this.handleQuantityChange(basketItemU30, u30Count, quantityU30, u30Price);
        if (resultU30) {
          const quantity = resultU30.quantity;
          if (resultNormal && resultNormal?.isAdding === resultU30.isAdding) {
            const isAdding = resultU30.isAdding;
            const item = getGtmItem({ product, price: u30Price, isAdding, coupon, quantity });
            items.push(item);
          } else {
            gtmCartActionEvent({
              product,
              price: u30Price,
              coupon,
              quantity,
              isAdding: resultU30.isAdding,
            }); // track separately
          }
        }
      }
      // multi flow - handle tracking of multiple items
      if (resultNormal?.isAdding && resultU30?.isAdding && resultNormal?.isAdding === resultU30?.isAdding) {
        if (resultNormal.isAdding === true && resultU30.isAdding === true) gtmAddToCartEvent(items);
        // track items in one event
        else if (resultNormal.isAdding === false && resultU30.isAdding === false) gtmRemoveFromCartEvent(items);
      } else if (resultNormal) {
        gtmCartActionEvent({
          product,
          price: defaultPrice,
          quantity: resultNormal.quantity,
          isAdding: resultNormal.isAdding,
        }); // track separately
      }
    },
    onHandleInfoData() {
      if (this.$store.state.basket?.usersCount) {
        // we are in family flow
        const allESim = this.$store.state.basket?.items.every((item) => item?.user?.simType === 'esim');
        if (allESim) this.$store.dispatch('app/addItem', { key: 'showDeliveryInfo', value: true });
        else this.$store.dispatch('app/deleteItem', 'showDeliveryInfo');
      }
    },
    async onUpdateBasketItemProduct({ basketItem, product }) {
      if (basketItem.product.id === product.id) {
        console.log(`${this.$options.name}: Same product - no need to update`);
        return;
      }
      const coupon = basketItem.coupon;
      const oldPrice = basketItem.price;
      let priceKey = basketItem.priceKey;
      let result = null;
      if (this.usersCount) {
        // family flow - update all items
        await this.getItems.forEach((item) => {
          const basketItemIndex = this.getItems.findIndex((f) => f.id === item.id);
          this.$store.dispatch('basket/updateBasketItemProduct', {
            index: basketItemIndex,
            product,
            priceKey: basketItem.priceKey,
          });
        });
      } else {
        if (this.isDiscountForOnlyOneProduct) {
          result = this.handleDiscountForOnlyOneProduct({ product, basketItem });
          if (result.addDiscount) priceKey = this.priceKeyPartner;
          else {
            priceKey = this.getPriceKeyByAge(basketItem);
          }
        }
        const basketItemIndex = this.getItems.findIndex((f) => f.id === basketItem.id);
        await this.$store.dispatch('basket/updateBasketItemProduct', {
          index: basketItemIndex,
          product,
          priceKey,
        });
      }
      console.log(
        `${this.$options.name}: Updated item in basket store: '${basketItem.product.displayName}' to '${product.displayName}'`,
      );
      const newItem = this.$store.getters['basket/getBasket'].items.find((item) => item.id === basketItem.id);
      const price = newItem.price;
      if (this.isDiscountForOnlyOneProduct && result?.updatedItem) {
        // combine items to track together
        const { removedItem, addedItem } = this.getGtmUpdatedProductItems(
          result.updatedItem.product,
          result.priceKeyNew,
          result.updatedItem.priceKey,
        );
        const removedItemByUser = getGtmItem({ product: basketItem.product, price: oldPrice, coupon, isAdding: false });
        const addedItemByUser = getGtmItem({ product, price, isAdding: true, coupon: newItem.coupon });

        const removedItems = [removedItem, removedItemByUser];
        const addedItems = [addedItem, addedItemByUser];

        // Remove duplicates within and between removedItems and addedItems
        const [uniqueRemovedItems, uniqueAddedItems] = removeDuplicateGtmItems(removedItems, addedItems);

        gtmRemoveFromCartEvent(uniqueRemovedItems);
        gtmAddToCartEvent(uniqueAddedItems);
        return;
      }
      gtmCartActionEvent({ product: basketItem.product, price: oldPrice, coupon, updateItem: true, isAdding: false });
      gtmCartActionEvent({ product, price, coupon, updateItem: true });
    },
    getPriceKeyByAge(basketItem) {
      if (this.isUserUnder30(basketItem)) return this.priceKeyU30;
      else return this.priceKeyNormal;
    },
    getMostExpensiveItem(items) {
      if (!items || items.length === 0) return null;
      return items.reduce((prev, current) => (prev.priceFull > current.priceFull ? prev : current));
    },
    getItemsWithSamePrice(items, price) {
      return items.filter((item) => item.priceFull === price);
    },
    isUserUnder30(basketItem) {
      if (!basketItem?.user?.birthday) return false; // assume user is over 30 if no birthday is set
      return calculateAge(basketItem.user.birthday) < 30;
    },
    getFirstBasketItemBasedOnIndex(items) {
      // Map items to their indices in getItems
      const indexedItems = items.map((item) => ({
        item,
        index: this.getItems.findIndex((basketItem) => basketItem.id === item.id),
      }));

      // Find the item with the minimum index
      const minItem = indexedItems.reduce((min, current) => (current.index < min.index ? current : min), {
        index: Infinity,
      }).item;

      return minItem;
    },
    handleDiscountForOnlyOneProduct({ product, isAdding = true, basketItem = null }) {
      // return true or false if the product should have a discount or not
      // and handle discount for the other possible product in the basket
      const normalItems = this.basket.items?.filter((item) => item.priceKey !== this.priceKeyPartner);
      const discountItem = this.basket.items?.find((item) => item.priceKey === this.priceKeyPartner);
      let priceKey = this.priceKeyPartner;
      if (discountItem) {
        normalItems?.push(discountItem); // TODO: evaluate if this can be done in a better way
      }
      if (!normalItems || normalItems?.length === 0) return { addDiscount: true };
      let mostExpensiveItemInBasket = this.getMostExpensiveItem(normalItems);
      let mostExpensiveItems = this.getItemsWithSamePrice(normalItems, mostExpensiveItemInBasket.priceFull);

      const productPrice = this.findPrice(product, this.getRatePlansDefault);

      // updating a basket item with a new product
      if (basketItem && isAdding) {
        if (basketItem.id === mostExpensiveItemInBasket.id) {
          const remainingItems = normalItems.filter((item) => item.id !== basketItem.id);
          const secondMostExpensiveItemInBasket = this.getMostExpensiveItem(remainingItems);

          if (productPrice > secondMostExpensiveItemInBasket.priceFull) {
            return { addDiscount: basketItem.priceKey === this.priceKeyPartner ? true : false };
          }
          mostExpensiveItems = this.getItemsWithSamePrice(remainingItems, secondMostExpensiveItemInBasket.priceFull);
          mostExpensiveItemInBasket = secondMostExpensiveItemInBasket;
        }

        let addDiscount = true;

        priceKey = this.getPriceKeyByAge(mostExpensiveItemInBasket);

        // Handle which item should have the discount
        if (mostExpensiveItems.length > 0) {
          if (productPrice < mostExpensiveItemInBasket.priceFull) {
            const firstItem = this.getFirstBasketItemBasedOnIndex(mostExpensiveItems);

            if (firstItem.id === discountItem.id) {
              return { addDiscount: false }; // Discount item found, do not add discount and do not update
            }

            addDiscount = false;
            mostExpensiveItemInBasket = firstItem;
            priceKey = this.priceKeyPartner;
          } else if (productPrice > mostExpensiveItemInBasket.priceFull) {
            // Product price is greater than the most expensive item in the basket
            addDiscount = true; // Add discount to new basket item
          } else {
            // Product price is the same as the most expensive item in the basket
            mostExpensiveItems.push(basketItem);
            const firstItem = this.getFirstBasketItemBasedOnIndex(mostExpensiveItems);

            if (firstItem.priceKey === this.priceKeyPartner) {
              return { addDiscount: false }; // Partner price item found, do not add discount and do not update
            }

            if (firstItem.id === basketItem.id) {
              addDiscount = true; // Add discount to the updated item
            } else {
              // Update the first item as partner
              addDiscount = false;
              mostExpensiveItemInBasket = firstItem;
              priceKey = this.priceKeyPartner;
            }
          }
        }
        let updatedItem = null;
        let priceKeyNew = null;

        if (mostExpensiveItemInBasket.priceKey !== priceKey) {
          const index = this.basket.items.findIndex((item) => item.id === mostExpensiveItemInBasket.id);

          this.$store.dispatch('basket/updateBasketItemProduct', {
            index,
            product: mostExpensiveItemInBasket.product,
            priceKey,
          });

          updatedItem = mostExpensiveItemInBasket;
          priceKeyNew = priceKey;
        }
        return { addDiscount, updatedItem, priceKeyNew };
      }
      // adding products to basket where the new product is the most expensive or same price as the most expensive
      if (isAdding && productPrice >= mostExpensiveItemInBasket.priceFull) {
        // remove the discount from the discount item if the user is under 30 and add it to the new product
        if (discountItem) {
          // no need to update if a product with the same price is already discounted and has a user over 30
          if (productPrice === discountItem.priceFull && !this.isUserUnder30(discountItem)) return { addDiscount: false };

          const priceKey = this.getPriceKeyByAge(discountItem);
          const index = this.basket.items.findIndex((item) => item.id === discountItem.id);
          this.$store.dispatch('basket/updateBasketItemProduct', {
            index,
            product: discountItem.product,
            priceKey,
          });
          return { addDiscount: true, updatedItem: discountItem, priceKeyNew: priceKey };
        }
      } else if (!isAdding && productPrice >= mostExpensiveItemInBasket.priceFull) {
        // removing products from basket where the removed product is the most expensive or same price as the most expensive
        let remainingItems = normalItems.filter((item) => item.id !== mostExpensiveItemInBasket.id);
        if (remainingItems.length === 0) {
          // the last item is being removed - fallback to the normal item if not discounted
          if (discountItem) return { addDiscount: true };
          return { addDiscount: false };
        }

        let addDiscount = false;

        // removing specific product from basket
        if (basketItem) {
          // current discount item is being removed
          if (basketItem.id === discountItem.id) {
            addDiscount = true;
            // find the item to update with a discount
            remainingItems = remainingItems.filter((item) => item.id !== basketItem.id);
            const secondMostExpensiveItemInBasket = this.getMostExpensiveItem(remainingItems);
            mostExpensiveItems = this.getItemsWithSamePrice(remainingItems, secondMostExpensiveItemInBasket.priceFull);
            if (mostExpensiveItems.length > 0) {
              mostExpensiveItemInBasket = this.getFirstBasketItemBasedOnIndex(mostExpensiveItems);
              priceKey = this.priceKeyPartner;
            }
          } else return { addDiscount }; // do not update another item if there is a discount item
        }

        // removing multiple products from basket
        if (!basketItem && productPrice === mostExpensiveItemInBasket.priceFull) {
          // Remove a normal item if there is a discount item and there are multiple most expensive items
          if (discountItem && mostExpensiveItems.length > 1) return { addDiscount };

          addDiscount = discountItem ? true : false;

          // find the item to update with a discount
          const secondMostExpensiveItemInBasket = this.getMostExpensiveItem(remainingItems);
          mostExpensiveItems = this.getItemsWithSamePrice(remainingItems, secondMostExpensiveItemInBasket.priceFull);
          if (mostExpensiveItems.length > 0) {
            mostExpensiveItemInBasket = this.getFirstBasketItemBasedOnIndex(mostExpensiveItems);
            priceKey = this.priceKeyPartner;
          }
        }
        if (mostExpensiveItemInBasket.priceKey === priceKey) return { addDiscount };

        const basketItemIndex = this.getItems.findIndex((f) => f.id === mostExpensiveItemInBasket.id);
        this.$store.dispatch('basket/updateBasketItemProduct', {
          index: basketItemIndex,
          product: mostExpensiveItemInBasket.product,
          priceKey,
        });
        return { addDiscount, updatedItem: mostExpensiveItemInBasket, priceKeyNew: priceKey };
      }
      return { addDiscount: false };
    },
    findPrice(product, ratePlans) {
      return ratePlans.find((ratePlan) => ratePlan.crmId === product.fields['CRM Item'].value)?.price;
    },
    handleQuantityChange(basketItem, currentQuantity, newQuantity, price) {
      const coupon = this.getCoupon(this.priceKeyDefault);
      let product = basketItem.product;
      let isAdding = true;
      let result = null;
      let diff = 0;
      const items = [];
      // removing items from basket
      if (currentQuantity > newQuantity) {
        diff = currentQuantity - newQuantity;
        isAdding = false;
        for (let i = 1; i <= diff; i++) {
          const basketItemCopy = { ...basketItem };
          if (this.isDiscountForOnlyOneProduct) {
            const hasItemNormal = this.getItems.some(
              (item) => item.product.id === basketItem.product.id && item.priceKey === this.priceKeyNormal,
            );
            const hasItemU30 = this.getItems.some(
              (item) => item.product.id === basketItem.product.id && item.priceKey === this.priceKeyU30,
            );
            if (hasItemNormal) basketItemCopy.priceKey = this.priceKeyNormal;
            else if (hasItemU30) basketItemCopy.priceKey = this.priceKeyU30;
            else basketItemCopy.priceKey = this.priceKeyPartner; // fallback to partner price

            if (i === diff) {
              result = this.handleDiscountForOnlyOneProduct({ product, isAdding });
              if (result.addDiscount) {
                basketItemCopy.priceKey = this.priceKeyPartner;
              }
            }
            items.push(basketItemCopy);
          }
          this.$store.dispatch('basket/removeBasketItemWithPriceKey', basketItemCopy);
          console.log(`${this.$options.name}: Removed item: '${basketItem.product.displayName}' from basket store`);
        }
        if (this.usersCount) {
          if (newQuantity > 0) product = null; // track family subscription only if it is the last item
          gtmCartActionEvent({ product, price, quantity: diff, coupon, isAdding });
          return;
        }
      } else if (currentQuantity < newQuantity) {
        // adding items to basket
        diff = newQuantity - currentQuantity;
        if (this.isDiscountForOnlyOneProduct) {
          result = this.handleDiscountForOnlyOneProduct({ product, isAdding });
        }

        for (let i = 0; i < diff; i++) {
          const basketItemCopy = { ...basketItem };
          if (this.isDiscountForOnlyOneProduct) {
            if (i === 0 && result.addDiscount) {
              basketItemCopy.priceKey = this.priceKeyDefault;
            } else basketItemCopy.priceKey = this.priceKeyNormal;
          }
          items.push(basketItemCopy);

          this.$store.dispatch('basket/addBasketItem', basketItemCopy);
          console.log(`${this.$options.name}: Saved item: '${basketItem.product.displayName}' for basket store`);
        }
        if (this.usersCount) {
          diff = diff - 1; // subtract 1 so total items match amount of users
          if (currentQuantity > 0) product = null; // track family subscription only when adding the first item
          gtmCartActionEvent({ product, price, quantity: diff, coupon });
          return;
        }
      }
      const returnObj = diff > 0 ? { isAdding, quantity: diff } : null; // to track multi flow in onSaveMultipleProducts
      if (!this.isDiscountForOnlyOneProduct) return returnObj;
      // tracking for gtm in partner multi flow where there is a discount for only one product
      const gtmItems = [];
      const itemMap = {}; // Use an object to track items by composite key

      items.forEach((item) => {
        const product = item.product;
        const price = this.findPrice(product, this.getRatePlansByPriceKey(item.priceKey));
        const coupon = this.getCoupon(item.priceKey); // Get coupon for tracking
        const compositeKey = `${product.id}_${item.priceKey}`; // Create a composite key

        if (itemMap[compositeKey]) {
          itemMap[compositeKey].quantity++;
        } else {
          const newItem = getGtmItem({ product, price, isAdding, coupon });
          itemMap[compositeKey] = newItem;
          gtmItems.push(newItem); // Add new item to the array
        }
      });

      let itemToTrackSeparately;
      if (result?.updatedItem && result?.priceKeyNew) {
        const { removedItem, addedItem } = this.getGtmUpdatedProductItems(
          result.updatedItem.product,
          result.priceKeyNew,
          result.updatedItem.priceKey,
        );
        if (isAdding) {
          itemToTrackSeparately = removedItem;
          gtmItems.push(addedItem);
        } else {
          itemToTrackSeparately = addedItem;
          gtmItems.push(removedItem);
        }
      }

      if (isAdding) {
        let uniqueAddedItems = gtmItems;
        let uniqueRemovedItems = [];
        if (itemToTrackSeparately) {
          [uniqueAddedItems, uniqueRemovedItems] = removeDuplicateGtmItems(gtmItems, [itemToTrackSeparately]);
          if (uniqueRemovedItems.length > 0) gtmRemoveFromCartEvent(uniqueRemovedItems);
        }
        gtmAddToCartEvent(uniqueAddedItems);
      } else {
        let uniqueRemovedItems = gtmItems;
        let uniqueAddedItems = [];
        if (itemToTrackSeparately) {
          [uniqueAddedItems, uniqueRemovedItems] = removeDuplicateGtmItems([itemToTrackSeparately], gtmItems);
        }
        gtmRemoveFromCartEvent(uniqueRemovedItems);
        if (uniqueAddedItems.length > 0) gtmAddToCartEvent(uniqueAddedItems);
      }
    },
    async onSaveSingleProduct(product) {
      const priceKey = this.priceKey;
      const items = this.$store.getters['basket/getBasket'].items;
      if (items?.length) {
        // update existing product and pricekey since we only ever have one product in single flow
        const basketItem = items[0].product;
        const price = items[0].price; // get price before removing the product
        // TODO: clarify if we should remove the old product from the basket here
        await this.$store.dispatch('basket/updateBasketItemProduct', { index: 0, product, priceKey });
        console.log(`${this.$options.name}: Removed item: '${basketItem.displayName}' in basket store`);
        console.log(`${this.$options.name}: Saved item: '${product.displayName}' in basket store`);
        const coupon = items[0].coupon;
        gtmCartActionEvent({ product: basketItem, price, coupon, isAdding: false });
      } else {
        // add new product
        const basketItem = { product, priceKey };
        await this.$store.dispatch('basket/addBasketItem', basketItem);
        console.log(`${this.$options.name}: Added new basket item: '${product.displayName}'`);
      }

      const newItem = this.$store.getters['basket/getBasket'].items[0];
      const price = newItem.price;
      const coupon = newItem.coupon;
      gtmCartActionEvent({ product, price, coupon });
    },
    getGtmUpdatedProductItems(product, priceKeyNew, priceKeyOld) {
      const priceNew = this.findPrice(product, this.getRatePlansByPriceKey(priceKeyNew));
      const priceOld = this.findPrice(product, this.getRatePlansByPriceKey(priceKeyOld));
      const couponNew = this.getCoupon(priceKeyNew);
      const couponOld = this.getCoupon(priceKeyOld);
      let removedItem, addedItem;

      // create GTM items for the removed and added product
      removedItem = getGtmItem({ product, price: priceOld, coupon: couponOld, isAdding: false });
      addedItem = getGtmItem({ product, price: priceNew, coupon: couponNew, isAdding: true });

      return { removedItem, addedItem };
    },
    onEmitValue(self, value) {
      if (self.fields.EmitValue.value) {
        this.$root.$emit(self.fields.Key.value, value);
      }
    },
    emitInputValue(self, value) {
      if (self.fields.EmitValue.value) {
        value = value.toString();
        this.$root.$emit(self.fields.Value.value, value);
      }
    },
    addToValidation(self) {
      if (self.fields.Validations && self.$vnode.tag) subscribeToValidation(self.fields.Validations, self);
    },
    removeFromValidation(self) {
      if (self.fields.Validations && self.$vnode.tag) unsubscribeFromValidation(self.fields.Validations, self);
    },
    trackEvents(self) {
      let trackingGoals = [];
      self.fields.Goals.forEach((goal) => {
        const eventType = goal.fields['Event Type'].value;
        const goalId = goal.fields?.Goal?.id;
        trackingGoals.push({ [eventType]: goalId });
      });
      if (trackingGoals.length) track(trackingGoals);
    },
    onGoToPreviousStep() {
      if (this.$store.state.app?.goBackToStep) {
        const step = this.$store.state.app.goBackToStep;
        this.$store.dispatch('app/deleteItem', 'goBackToStep');
        console.log('onGoToPreviousStep: Routing to', step);
        this.$router.push(step);
      }
    },
    async onCreateOrderEvent() {
      if (this.loading) return;
      this.loading = true;

      try {
        if (!this.$store.getters['basket/allUsersCompleted']) {
          this.$root.$emit('onErrorUsersNotComplete', 'complete all user data before placing an order');
          return;
        }
        const body = this.$store.getters['basket/getBasketState'];
        const response = await registerOrder(body);
        console.log(`${this.$options.name}: Register order response: ${JSON.stringify(response, null, 4)}`);

        // check for bad response
        if (response.status !== 200) {
          console.error(response.Message);
          this.$root.$emit('onError', null);
          return;
        }

        // read orderId from response and save it so we can show it on receipt page
        const orderId = response.data.OrderId;
        this.$store.dispatch('app/addItem', { key: 'orderId', value: orderId });
        console.log(`${this.$options.name}: Saved value: '${orderId}' for key: 'orderId'`);

        // everything went well, tell GTM
        const value = Math.round(response.data.TotalPriceWithDiscount);
        const tax = Math.round(response.data.PriceWithDiscountVat);
        const items = getGtmItems();

        // a list of all Telenordic IDs in the order
        let tnIDs = [orderId, ...response.data.UserOrders.map((userOrder) => userOrder.OrderId)];
        tnIDs = [...new Set(tnIDs)].join(','); // remove duplicates and create a comma separated string

        gtmPurchaseEvent({ transaction_id: orderId, tnIDs, value, tax, items, gtmSharedParams: gtmSharedParams() });

        // we successfully placed an order :)
        this.$root.$emit('onSuccess', null);
      } catch (error) {
        console.error(error);
        if (error.response?.status === 422) {
          this.$root.$emit('onError422', 'Phone number is an existing TM number and cannot be transfered');
          return;
        }
        this.$root.$emit('onError', null);
      } finally {
        this.loading = false;
      }
    },
    onErrorMessage(message) {
      gtmErrorMessageEvent({ message, gtmSharedParams: gtmSharedParams() });
    },
    saveRecruitmentCode(code) {
      this.$store.dispatch('app/addItem', { key: 'recruitmentCode', value: code });
      console.log(`${this.$options.name}: Saved value: '${code}' for key: 'recruitmentCode'`);
    },
    saveRecruiterName(data) {
      const name = data?.firstName + ' ' + data?.lastName;
      this.$store.dispatch('app/addItem', { key: 'recruiterName', value: name });
      console.log(`${this.$options.name}: Saved value: '${name}' for key: 'recruiterName'`);
    },
    async savePortingKeepNumber() {
      await this.$store.dispatch('basket/appendToCurrentUser', { key: 'porting', value: 'keep-number' });
      console.log(`${this.$options.name}: Saved value: 'keep-number' for key: 'porting' for current user'`);
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  background-color: #{$color-grey};
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #{$color-grey-dark};
  border-radius: 2px;
}

body {
  font-family: 'CircularProBook', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  background: #{$color-white};
  color: #{$color-black};
  @include text('m', $lineHeight: true);

  @include screen-tablet-portrait-up {
    @include text-md('m', $lineHeight: true);
    background-color: #{$color-white};
  }

  &.no-scroll {
    overflow-y: hidden;
  }
}

a {
  text-decoration: none;
  color: #{$color-black};
}

// Add icon for external links
a[target='_blank'] {
  display: inline-block;
  position: relative;
  padding-bottom: 0;
  margin-right: 32px;
  line-height: normal;

  &:after {
    content: '\e918';
    font-family: $talkmore-icons;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    width: 24px;
    bottom: 0;
    top: -3px;
    right: -30px;
  }
  &:focus-visible {
    padding-right: 32px;
    margin-right: 0;
    &:after {
      color: #{$color-white};
      right: 2px;
    }
  }
}

.layout {
  .steps-container .container {
    width: 100%;
    margin-bottom: 80px;
    margin: 0 16px 64px;
    width: Calc(100% - #{32px});
    max-width: 564px;
    min-height: Calc(100vh - #{212px});
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    flex: 1;
    @include screen-tablet-portrait-up {
      min-height: auto;
    }

    @include phone-xs {
      margin: 0 12px 64px;
      width: Calc(100% - #{24px});
    }

    @include screen-tablet-portrait-up {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 24px 32px;
    }
  }
}

.button-container {
  max-width: 564px;
  width: 100%;
}
</style>
