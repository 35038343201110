<template>
  <div class="calendar desktop-narrow">
    <DatePicker
      v-if="visible"
      v-model="date"
      locale="nb"
      :masks="{ input: ['DD.MM.YYYY'] }"
      color="green"
      :popover="{ visibility: 'focus' }"
      :theme="themeStyles"
      :min-date="minValue"
      @input="changed($event)"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <ScText :for="fields.Key.value" tag="label" class="calendar__label" :field="fields.LabelText" />
        <div class="calendar__content">
          <input
            type="text"
            class="input-field__content-input"
            :id="fields.Key.value"
            :name="fields.Key.value"
            :value="inputValue"
            v-on="inputEvents"
          />
          <i class="icon icon-calendar"></i>
          <slot></slot>
        </div>
      </template>
    </DatePicker>

    <div v-show="isEditorActive">
      <ScText :for="fields.Key.value" tag="label" class="calendar__label" :field="fields.LabelText" />
      <div class="calendar__content">
        <input type="text" class="input-field__content-input" />
        <i class="icon icon-calendar"></i>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Calendar',
  components: {
    ScText: Text,
    DatePicker: () => {
      // library isn't compatible with SSR, so it's skipped when SSR
      if (typeof window !== 'undefined') return import('v-calendar/lib/components/date-picker.umd');
    },
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    onEmitValue: {
      type: Function,
    },
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      date: this.$store.state.app[this.fields.Key.value] || new Date(),
      themeStyles: {
        dayNotInMonth: 'not-in-month',
      },
      visible: false,
      value: new Date(),
      minValue: new Date().setFullYear(new Date().getFullYear() - 2),
    };
  },
  beforeDestroy() {
    this.removeFromValidation(this);
    this.$root.$off('onSave');
  },
  mounted() {
    this.addToValidation(this);

    this.$root.$on('onSave', () => {
      this.onSaveEvent(this.fields.Key.value, this.date, this.$options.name);
    });

    if (this.fields.ToggledBy) {
      this.$root.$on(this.fields.ToggledBy.fields.Key.value, (data) => {
        this.visible = data;
        if (this.visible) this.date = this.$store.state.app[this.fields.Key.value] || new Date();
        // reset date back to today if datepicker is hidden again
        if (!data) this.changed(new Date());
      });
    }
  },
  methods: {
    changed(e) {
      // we want to remove our date from state when visibility is toggled off
      if (!this.visible) {
        this.$store.dispatch('app/deleteItem', this.fields.Key.value);
        this.date = undefined;
        return;
      }

      this.value = e;
      this.$emit('change', e);

      if (this.fields.Validations) this.$root.$emit('onValidate', this);
      this.onEmitValue(this, this.value);
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss">
.calendar {
  flex-direction: column;

  &__label {
    font-family: 'CircularProBold', Helvetica, Arial, sans-serif;
    text-align: left;
    margin: 0 0 8px 24px;

    @include desktop {
      margin: 0 0 8px 0;
    }
  }

  &__content {
    margin: 24px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    position: relative;

    @include desktop {
      margin: 8px 0 24px;
    }

    i {
      position: absolute;
    }

    .icon {
      content: '';
      position: absolute;
      right: 16px;
      top: 15px;
      font-size: 28px;
      pointer-events: none;
    }

    &-input {
      background: #{$color-white};
      border: 1px solid #{$color-grey-dark};
      box-sizing: border-box;
      border-radius: 4px;
      height: 56px;
      width: 100%;
      display: block;
      outline-color: #{$color-green-darker};
    }
  }
}

.vc {
  max-width: 100%;
  &-highlight {
    border-color: #{$color-green} !important;
    background: #{$color-green} !important;
  }
  &-day-content {
    color: #{$color-black} !important;
  }
}

.vc-pane,
.vc-weeks,
.vc-pane-layout,
.vc-popover-content,
.vc-container {
  width: calc(100%) !important;
}
.vc-popover-content-wrapper {
  width: 376px !important;
  max-width: 100% !important;
}

.is-not-in-month {
  span {
    opacity: 0.3 !important;
  }
}
.vc-weekday {
  font-weight: bold !important;
  color: #000 !important;
}

.vc-weeks {
  padding-top: 16px !important;
}

.vc-title {
  padding-top: 8px !important;
  text-transform: capitalize;
}

.vc-arrows-container {
  margin-top: 8px !important;
}
</style>
